.carousel-slider {
  height: 100%;
  overflow: visible !important;
}

.carousel .carousel-slider {
  overflow: visible !important;
}

.carousel-dots {
  position: absolute;
  bottom: 10rem;
  left: 0;
}
.carousel .control-dots {
  width: auto !important;
  left: 8.5%;
  position: absolute !important; 
  bottom: 6% !important;
  display: flex;

  @media (max-width: 1024px){
    left: 2%;
  }
}

.slider-wrapper {
  overflow: visible !important;
}

.slider-wrapper,
.slider,
.slide {
  height: 100% !important;
  width: 100%;
}

// .carousel .slide img {
//   width: auto;
// }
