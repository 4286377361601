/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @responsive {
    .text-xxs {
      font-size: 11px;
    }

    .rotate-75 {
      --tw-rotate: 75deg;
    }

    .w-100{
      width: 28rem;
    }

    .decoration-white{
      text-decoration-color: #fff;
    }

    .w-45\/100{
      width: 45%;
    }
  }
}

@layer components{
  .heading-main{
    @apply text-3xl 2xl:text-4xl 3xl:text-5xl font-bold mt-4 uppercase leading-none;
  }

  .text-main{
    @apply text-base 3xl:text-xl mt-4 leading-none 3xl:leading-tight;
  }

  .btn-primary{
    @apply bg-accent duration-150 text-white px-6 2xl:px-10 text-base 2xl:text-xl 3xl:text-2xl font-bold mt-6 2xl:mt-10 py-2 2xl:py-3 uppercase inline-block ;
  }
}
