@media only screen and (max-width: 768px) {
   .question-number {
      background: rgba(227, 6, 19, var(--tw-border-opacity));
      display: block;
      width: auto;
      position: absolute;
      z-index: 100;
      margin-top: 0;
      padding: 10px;
      font-size: 2rem;
   }
}

@keyframes pulsing{
   0%{
      transform: scale(1);
   }
   50%{
      transform: scale(1.1);
   }
   100%{
      transform: scale(1);
   }
}

.pulse-answer{
   font-weight: 900;
   transition: font-weight .2s;
   animation: pulsing 1s ease-in-out infinite;
}