.reward-container {
  background: #000;
  background: -webkit-radial-gradient(center, #580207e6 0%, #000 60%);
  background: -moz-radial-gradient(center, #580207e6 0%, #000 60%);
  background: radial-gradient(ellipse at center, #580207e6 0%, #000 60%);
}

.reward-container-set {
    background: #000;
    background: -webkit-radial-gradient(center, #580207e6 0%, #000 35%);
    background: -moz-radial-gradient(center, #580207e6 0%, #000 35%);
    background: radial-gradient(ellipse at center, #580207e6 0%, #000 35%);
  }
  