.hide-1,
.hide-2 {
  display: none;
}

@media (max-width: 425px) {
  .hide-1 {
    display: block;
  }
}

@media (min-width: 386px) and (max-width: 430px) {
  .hide-2 {
    display: block;
  }
}
