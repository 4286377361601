@keyframes tomato {
  0% {
    transform: scale(1) rotate(-15deg);
  }
  50% {
    transform: scale(1.45) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(-15deg);
  }
}

.tomato {
  animation: tomato 1.4s linear infinite;
}
