.mobile-menu {
  display: none;
  right: 0;
  width: 50vw;

  height: 100vh;
  z-index: 49;

  transform: translateX(100%);
  transition: all 0.35s cubic-bezier(0.17, 0.67, 0.71, 0.9);
}

.mobile-menu.open {
  transform: translateX(0);
}

@media only screen and (max-width: 768px) {
  .toggler {
    right: 20px;
    top: 0;
    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #cdcdcd;
      border-radius: 3px;
      transition: all .2s;
    }
    cursor: pointer;
  }
  .toggler-open {
    span {
      z-index: 99999999999;
    }
    span:first-child {
      transform: rotate(45deg) translate(-2px, -1px);
      margin-bottom: -4px;
    }
    span:last-child {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }

  .toggler.open {
    span:first-child {
      transform: rotate(45deg) translate(6px, 6px);
    }
    span:nth-child(2){
       transform: translateX(-20px);
       opacity: 0;
    }
    span:last-child {
      transform: rotate(-45deg) translate(6px, -6px);
    }
  }

  .mobile-hidden {
    display: none;
  }
  .mobile-visible {
    display: block;
  }
  .mobile-menu {
    display: block;
    width: 75vw;
  }
}
